/**
 * Takes a space-separated string and abbreviates it to either the first 2 leters
 * if it's 1 word, or the first 2 initials for more than 1 word.
 */
export function abbreviateString(text: string, length = 2): string {
  return text
    .trim()
    .split(' ')
    .map((subString, _, array) =>
      array.length === 1 ? subString : subString[0],
    )
    .join('')
    .slice(0, length);
}
