export const Select = {
  parts: [
    'root',
    'trigger',
    'value',
    'icon',
    'content',
    'viewport',
    'group',
    'item',
    'itemText',
    'itemIndicator',
    'label',
    'separator',
    'scrollUpButton',
    'scrollDownButton',
  ],
  baseStyle: {},
};
