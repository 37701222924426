import { Box, forwardRef, HTMLChakraProps, useStyles } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export type ScrimBackgroundProps = HTMLChakraProps<'div'>;

export const ScrimBackground = forwardRef<ScrimBackgroundProps, 'div'>(
  (props, ref) => {
    const styles = useStyles();
    const backgroundStyles = {
      position: 'absolute',
      top: 0,
      left: 0,
      height: 'full',
      width: 'full',
      zIndex: 0,
      pointerEvents: 'none',
      userSelect: 'none',
      overflow: 'hidden',
      ...styles?.background,
    };

    return (
      <Box
        as="div"
        role="presentation"
        ref={ref}
        {...props}
        __css={backgroundStyles}
      />
    );
  },
);

if (__DEV__) {
  ScrimBackground.displayName = 'ScrimBackground';
}
