import { Box, forwardRef, HTMLChakraProps, useStyles } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export type ScrimBodyProps = HTMLChakraProps<'div'>;

export const ScrimBody = forwardRef<ScrimBodyProps, 'div'>((props, ref) => {
  const styles = useStyles();
  const bodyStyles = {
    position: 'relative',
    zIndex: 2,
    ...styles.body,
  };

  return <Box as="div" ref={ref} {...props} __css={bodyStyles} />;
});

if (__DEV__) {
  ScrimBody.displayName = 'ScrimBody';
}
