import {
  Box,
  BoxProps,
  forwardRef,
  useStyles,
  ThemingProps,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';
import { useMemo } from 'react';
import { abbreviateString } from '../utils/abbreviateString';
import { getAvatarColors } from '../utils/getAvatarColors';

type AbbreviateProps = ThemingProps<'Avatar'> &
  BoxProps & {
    children: string;
    colorSeed?: string;
    length?: 1 | 2;
  };

export const AvatarAbbreviate = forwardRef(
  ({ children, colorSeed, length, ...restProps }: AbbreviateProps, ref) => {
    const styles = useStyles();
    const colors = useMemo(() => {
      return getAvatarColors(colorSeed || children);
    }, [colorSeed, children]);

    const abbreviateStyles = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: 'inherit',
      width: '100%',
      height: '100%',
      textTransform: 'uppercase',
      fontWeight: 600,
      ...colors,
      ...styles.abbreviate,
    };
    return (
      <Box {...restProps} __css={abbreviateStyles} ref={ref}>
        {abbreviateString(children, length)}
      </Box>
    );
  },
);

if (__DEV__) {
  AvatarAbbreviate.displayName = 'AvatarAbbreviate';
}
