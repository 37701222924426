import {
  Box,
  forwardRef,
  HTMLChakraProps,
  StylesProvider,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export type ScrimProps = HTMLChakraProps<'div'>;

export const Scrim = forwardRef<ScrimProps, 'div'>(
  ({ children, ...props }, ref) => {
    const styles = useMultiStyleConfig('Scrim', props);
    const rootStyles = {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...styles.root,
    };

    return (
      <Box as="div" ref={ref} {...props} __css={rootStyles}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Box>
    );
  },
);

if (__DEV__) {
  Scrim.displayName = 'Scrim';
}
