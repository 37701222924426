import { CSSProperties } from 'react';
import seedrandom from 'seedrandom';

type ColorOption = Pick<CSSProperties, 'backgroundColor' | 'color'>;

const avatarColorOptions: ColorOption[] = [
  { color: '#164832', backgroundColor: '#c6f5d6' },
  { color: '#0c0146', backgroundColor: '#ddd8fd' },
  { color: '#003d66', backgroundColor: '#a8d9fc' },
  { color: '#872600', backgroundColor: '#ffdcaf' },
  { color: '#870000', backgroundColor: '#f5cccb' },
];

export function getAvatarColors(seed?: string): ColorOption {
  const generateRandomNumber = seedrandom(seed);
  const colorIndex = Math.floor(
    generateRandomNumber() * avatarColorOptions.length,
  );
  return avatarColorOptions[colorIndex];
}
