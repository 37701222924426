import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = ({ colorScheme }) => ({
  borderColor: `${colorScheme ?? 'blue'}.200`,
  backgroundColor: `${colorScheme ?? 'blue'}.100`,
  color: `${colorScheme ?? 'blue'}.800`,
});

export const Banner = {
  parts: ['title', 'description'],
  baseStyle: baseStyle,
};
