import {
  chakra,
  forwardRef,
  ThemingProps,
  useStyleConfig,
  HTMLChakraProps,
} from '@chakra-ui/system';
import { __DEV__ } from '@chakra-ui/utils';
import * as React from 'react';

export type HeadingProps = HTMLChakraProps<'h2'> & ThemingProps<'Heading'>;

export const Heading = forwardRef<HeadingProps, 'h2'>((props, ref) => {
  const styles = useStyleConfig('Heading', props);

  return <chakra.h2 ref={ref} {...props} __css={styles} />;
});

if (__DEV__) {
  Heading.displayName = 'Heading';
}
