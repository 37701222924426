import * as RadixAvatar from '@radix-ui/react-avatar';
import {
  StylesProvider,
  useMultiStyleConfig,
  chakra,
  forwardRef,
  ThemingProps,
  HTMLChakraProps,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

type AvatarProps = RadixAvatar.AvatarProps &
  ThemingProps<'Avatar'> &
  HTMLChakraProps<'div'>;

const StyledAvatar = chakra(RadixAvatar.Root);

export const Avatar = forwardRef<AvatarProps, 'div'>(
  ({ children, size, ...restProps }: AvatarProps, ref) => {
    const styles = useMultiStyleConfig('Avatar', { size });
    const rootStyles = {
      display: 'inline-flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderRadius: 4,
      ...styles.root,
    };

    return (
      <StyledAvatar {...restProps} __css={rootStyles} ref={ref}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </StyledAvatar>
    );
  },
);

if (__DEV__) {
  Avatar.displayName = 'Avatar';
}
