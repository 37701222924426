import { sizes } from '@dixa/tokens';

export const Button = {
  sizes: {
    md: {
      paddingY: sizes.spacer[250],
      minHeight: '40px',
    },
  },
};
