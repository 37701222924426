import { Box, forwardRef, HTMLChakraProps, useStyles } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export type ScrimBackdropProps = HTMLChakraProps<'div'>;

export const ScrimBackdrop = forwardRef<ScrimBackdropProps, 'div'>(
  (props, ref) => {
    const styles = useStyles();
    const backdropStyles = {
      position: 'absolute',
      top: 0,
      left: 0,
      height: 'full',
      width: 'full',
      zIndex: 1,
      pointerEvents: 'none',
      userSelect: 'none',
      overflow: 'hidden',
      ...styles?.background,
    };

    return (
      <Box
        as="div"
        role="presentation"
        ref={ref}
        {...props}
        __css={backdropStyles}
      />
    );
  },
);

if (__DEV__) {
  ScrimBackdrop.displayName = 'ScrimBackdrop';
}
