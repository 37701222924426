import { colors, typography } from '@dixa/tokens';

export const styles = {
  global: () => ({
    html: {
      fontSize: typography.size[300],
    },
    body: {
      fontFamily: 'body',
      color: '#1D1D1B',
      bg: '#ffffff',
      lineHeight: 'base',
      fontWeight: typography.weight.normal,
    },
    '*::placeholder': {
      color: 'neutral.500',
    },
    '*, *::before, &::after': {
      borderColor: 'gray.300',
      wordWrap: 'break-word',
    },
    ['a:not([class]), a.link']: {
      color: colors.purple[600],
      _hover: {
        textDecoration: 'underline',
      },
      _focus: {
        borderRadius: '2px',
      },
    },
    ['a:not(.chakra-linkbox__overlay, .chakra-button)']: {
      _focus: {
        outline: `2px solid ${colors.purple[300]}`,
        outlineOffset: '1px',
      },
    },
  }),
};
