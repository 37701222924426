import * as RadixAvatar from '@radix-ui/react-avatar';
import {
  chakra,
  forwardRef,
  useStyles,
  ThemingProps,
  HTMLChakraProps,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

type AvatarImageProps = RadixAvatar.AvatarImageProps &
  ThemingProps<'Avatar'> &
  HTMLChakraProps<'img'>;

const StyledImage = chakra(RadixAvatar.Image);

export const AvatarImage = forwardRef<AvatarImageProps, 'img'>(
  (props: RadixAvatar.AvatarImageProps, ref) => {
    const styles = useStyles();
    const imageStyles = {
      borderRadius: 'inherit',
      width: '100%',
      height: '100%',
      ...styles,
    };
    return <StyledImage {...props} __css={imageStyles} ref={ref} />;
  },
);

if (__DEV__) {
  AvatarImage.displayName = 'AvatarImage';
}
