import {
  forwardRef,
  HTMLChakraProps,
  IconButton,
  omitThemingProps,
  StylesProvider,
  ThemingProps,
  useMultiStyleConfig,
  useStyleConfig,
} from '@chakra-ui/react';

import { Box } from '../box';
import { HStack } from '../stack';
import { __DEV__ } from '@chakra-ui/utils';
import CloseIcon from '../../icons/close.svg';
import { MouseEventHandler } from 'react';

export type BannerProps = HTMLChakraProps<'div'> &
  ThemingProps<'Banner'> & {
    title?: string;
    description?: string;
    isClosable?: boolean;
    onCloseClick?: MouseEventHandler<HTMLButtonElement>;
    icon?: React.ReactNode;
  };

export type BannerTitleProps = HTMLChakraProps<'div'>;

export const BannerTitle = forwardRef<BannerTitleProps, 'h2'>(
  ({ children, ...rest }, ref) => {
    const _styles = useStyleConfig('title', rest);
    const styles = {
      fontSize: 'sm',
      fontWeight: 'bold',
      width: '100%',
      marginTop: '-1px', // to align with icon
      ..._styles,
    };

    return (
      <Box ref={ref} as="h2" {...rest} __css={styles}>
        {children}
      </Box>
    );
  },
);

export type BannerDescriptionProps = HTMLChakraProps<'div'>;

export const BannerDescription = forwardRef<BannerDescriptionProps, 'p'>(
  ({ children, ...rest }, ref) => {
    const _styles = useStyleConfig('description', rest);
    const styles = {
      fontSize: 'sm',
      ..._styles,
    };

    return (
      <Box as="p" ref={ref} {...rest} __css={styles}>
        {children}
      </Box>
    );
  },
);

export const Banner = forwardRef<BannerProps, 'div'>(
  ({ title, description, icon, isClosable, onCloseClick, ...props }, ref) => {
    const rest = omitThemingProps(props);
    const _styles = useMultiStyleConfig('Banner', props);
    const styles = {
      gap: 3,
      padding: 3,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'md',
      ..._styles,
    };

    return (
      <StylesProvider value={styles as any}>
        <HStack role="alert" align="start" ref={ref} {...rest} __css={styles}>
          <Box flexShrink={0}>{icon}</Box>
          <Box flexGrow={1}>
            {title && <BannerTitle>{title}</BannerTitle>}
            {description && (
              <BannerDescription>{description}</BannerDescription>
            )}
          </Box>
          {isClosable && (
            <Box flexShrink={0} display="flex" alignItems="start">
              <IconButton
                aria-label="Close banner"
                minWidth={0}
                width="24px"
                height="24px"
                marginTop="-4px"
                marginRight="-4px"
                variant="ghost"
                onClick={onCloseClick ?? undefined}
                _hover={{
                  backgroundColor: 'transparent',
                }}
                _active={{
                  backgroundColor: 'transparent',
                }}
                icon={<CloseIcon />}
              />
            </Box>
          )}
        </HStack>
      </StylesProvider>
    );
  },
);

if (__DEV__) {
  Banner.displayName = 'Banner';
}
