import { styles } from './styles';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { shadows } from './foundations/shadows';
import { transition } from './foundations/transition';

import { colors as semanticTokensColors } from './semanticTokens/colors';

import { extendTheme } from '@chakra-ui/react';
import { Avatar } from './components/avatar';
import { Select } from './components/select';
import { Banner } from './components/banner';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Input } from './components/input';

const _chakraDefaultTheme = extendTheme({});

// Delete default theming for components with conflicting names
delete _chakraDefaultTheme.components.Select;
delete _chakraDefaultTheme.components.Avatar;

export const theme = extendTheme(
  {
    colors,
    fonts,
    shadows,
    transition,
    styles,
    semanticTokens: {
      colors: semanticTokensColors,
    },
    components: {
      Avatar,
      Select,
      Banner,
      Button,
      Checkbox,
      Input,
    },
  },
  _chakraDefaultTheme,
);
