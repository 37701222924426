import {
  forwardRef,
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  RequiredIndicator,
  useFormControlContext,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export const Checkbox = forwardRef<CheckboxProps, 'input'>((props, ref) => {
  const field = useFormControlContext();

  return (
    <ChakraCheckbox ref={ref} {...props}>
      {props.children}
      {field && <RequiredIndicator />}
    </ChakraCheckbox>
  );
});

if (__DEV__) {
  Checkbox.displayName = 'Checkbox';
}
