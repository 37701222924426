export const Avatar = {
  parts: ['root', 'image', 'abbreviate'],
  baseStyle: {},
  sizes: {
    '2xs': {
      root: {
        width: 3,
        height: 3,
      },
      abbreviate: {
        fontSize: 'xs',
        lineHeight: '16px',
      },
    },
    xs: {
      root: {
        width: 4,
        height: 4,
      },
      abbreviate: {
        fontSize: 'xs',
        lineHeight: '16px',
      },
    },
    sm: {
      root: {
        width: 5,
        height: 5,
      },
      abbreviate: {
        fontSize: 'sm',
        lineHeight: '16px',
      },
    },
    md: {
      root: {
        width: 8,
        height: 8,
      },
      abbreviate: {
        fontSize: 'md',
        lineHeight: '16px',
      },
    },
    lg: {
      root: {
        width: 10,
        height: 10,
      },
      abbreviate: {
        fontSize: 'lg',
        lineHeight: '24px',
        letterSpacing: '-0.24px',
      },
    },
    xl: {
      root: {
        width: 12,
        height: 12,
      },
      abbreviate: {
        fontSize: 'md',
        lineHeight: '24px',
        letterSpacing: '-0.24px',
      },
    },
    '2xl': {
      root: {
        width: 16,
        height: 16,
      },
      abbreviate: {
        fontSize: '2xl',
        lineHeight: '24px',
        letterSpacing: '-0.24px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
