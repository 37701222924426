import { colors as dixaColors } from '@dixa/tokens';

export const colors = {
  purple: dixaColors.purple,
  gray: dixaColors.neutral,
  red: dixaColors.red,
  blue: dixaColors.blue,
  green: dixaColors.green,
  orange: dixaColors.orange,
  neutral: dixaColors.neutral,
};
